import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import styled from "styled-components"
import HeroBlock from "../components/Core/HeroBlock";
import {graphql} from "gatsby";
import {Link} from "gatsby";
import bambooPanel from "../resources/img/bamboo-panels/panel-desktop.png";
import bambooPanelMobile from "../resources/img/bamboo-panels/panel-mobile.png";
import fonts from "./../styles/fonts"
import colors from "./../styles/colors"
import breakpoints from "../styles/breakpoints";

const PageContainer = styled.div`
`;

const StyledContentContainer = styled.div`
  width: 100%;
  max-width: 932px;
  margin: auto;
  padding: 0 40px 100px 40px;

  @media (min-width: ${breakpoints.lg}) {
    padding: 40px 40px 100px 40px;
  }
`;

const ArticleDate = styled.div`
  font-size: 14px;
  line-height: 26px;

  @media (min-width: ${breakpoints.lg}) {
    margin-top: 12px;
  }
  
  @media (min-width: ${breakpoints.xl}) {
    margin-top: 24px;
  }
`;

const ArticleContainer = styled.div`
  background: url(${bambooPanelMobile}) no-repeat;
  background-size: 100% auto;
  width: 100%;
  margin: 0 auto 20px auto;
  color: ${colors.black};
  height: 400px;
  max-width: 305px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 17px;
    line-height: 26px;
    margin-bottom: 8px;
  }
  
  h2 {
    ${fonts.gloryBold};
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;

    @media (min-width: ${breakpoints.md}) {
      margin-bottom: 8px;
    }
  }
  
  a {
    color: ${colors.black};
    text-decoration: none;
    padding-bottom: 2px solid ${colors.black};
    border-bottom: 1px solid ${colors.black};
    cursor: pointer;
  
    &:hover {
      color: ${colors.blue};
      border-color: ${colors.blue};
    }
  }
  
  img {
    max-width: 100%;
    margin-bottom: 40px;
  }

  @media (min-width: ${breakpoints.md}) {
    background: url(${bambooPanel}) no-repeat;
    background-size: 100% auto;
    margin: 0 auto 40px auto;
    height: 250px;
    max-width: 100%;
  }
`;

const ArticleContent = styled.div`
  width: 100%;
  margin: auto;
  max-width: 200px;
  
  @media (min-width: ${breakpoints.md}) {
    padding: 40px 32px;
    height: 235px;
    max-width: 550px;
  }
`;

const BlogPage = ({location, data}) => {
  const {articles} = data;

  let articlesData = [];

  articles.edges.map((article) => {
    articlesData.push(article.node);
  });

  return (
    <Layout hero={"true"}>
      <Seo title={"News | Panda Dynasty"}
           description={"Latest news from Panda Dynasty"}
           path={location.pathname}/>
      <PageContainer>
        <HeroBlock title={"News"}/>
        <StyledContentContainer>
          {articlesData.map((article) => {
            return <ArticleContainer>
              <ArticleContent>
                <ArticleDate>
                  {article.publishedDate}
                </ArticleDate>
                <h2><Link to={`/news/${article.slug}`}>{article.title}</Link></h2>
                {article.previewText}
              </ArticleContent>
            </ArticleContainer>
          })}
        </StyledContentContainer>
      </PageContainer>
    </Layout>
  )
}

export default BlogPage;

export const pageQuery = graphql`
  query BlogPageQuery {
    articles: allContentfulArticle(sort: {fields: publishedDate, order: DESC}) {
      edges {
        node {
          title
          slug
          previewText
          publishedDate(formatString: "DD MMM, YYYY")
          intro {
            raw
          }
        }
      }
    }   
  }
`
