import * as React from "react"
import styled from "styled-components"
import H1 from "../Core/H1";
import breakpoints from "../../styles/breakpoints";
import spaceStation from "../../resources/img/background/space-station.png";
import Nav from "./Hero/Nav";
import Logo from "./Hero/Logo";

const HeroContainer = styled.div`
  width: 100%;
  //height: 720px;
  margin-bottom: 48px;
  position: relative;
  background: ${(props) => props.image ? `url(${props.image})` : `url(${spaceStation})`} no-repeat;
  background-size: cover;
  background-position: center center;

  @media (min-width: ${breakpoints.md}) {
    //height: 680px;
  }
`;

const HeroContent = styled.div`
  text-align: center;
  padding: 0 0 32px 0;

  @media (min-width: ${breakpoints.xl}) {
    padding: 0 75px 32px 75px;
  }
`;

const LogoContainer = styled.div`
  margin-bottom: 8px;

  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: 40px;
  }
`;

const TitleContainer = styled.div`
  margin-bottom: 88px;

  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: 120px;
  }
`;

const HeroBlock = ({ title, image = null }) => {
  return (
    <HeroContainer image={image}>
      <HeroContent>
        <Nav />
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <TitleContainer>
          <H1>{title}</H1>
        </TitleContainer>
      </HeroContent>
    </HeroContainer>
  )
}

export default HeroBlock;
