import * as React from "react"
import styled from "styled-components"
import breakpoints from "../../../styles/breakpoints";
import logo from "../../../resources/img/home/logo-home.svg";
import {Link} from "gatsby";

const LogoImage = styled.img`
  margin-bottom: 32px;
  height: 72px;
  
  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: 32px;
  }
`;

const Logo = () => {
  return (
    <Link to={"/"}>
      <LogoImage src={logo} alt={"Panda Dynasty"}/>
    </Link>
  )
}

export default Logo;
